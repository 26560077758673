<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd9KUErTDK78JG__p6smejfuc08nWx0CucL3HBWFsKQqK41dA/viewform?embedded=true" width="100%" height="1500" frameborder="0" marginheight="0" marginwidth="0">{{ $t('action.loading') }}</iframe>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    data:()=>({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("route.PageMainHome"),
        to:{name:'PageMainHome'},
        exact:true,
      })
      this.breadcrumbs.push({
        text:this.$t("route.PageMainApply"),
        to:{name:'PageMainApply'},
        exact:true,
      })
      //EOC
    },
  }
</script>
